/**
 * NE PAS SUPPRIMER !
 * UTILISE DANS LA LISTE et DETAIL POUR LES BOUTONS D'ACTION
 */

function openAdminFromTicket() {
  var that = window['component']

  that.utilities.getData({
    dataName: 'contacts',
    dataId: that.data['company_contact_id_data'].id,
    callbackSuccess: function (response) {
      var kfId = response.data[0].text1
      var win = window.open(
        'https://accounts.kizeo.com/admin/customer_details/' + kfId,
        '_blank',
      )
      win.focus()
    },
  })
}

function SpecTransformAsContact(dataItem) {
  var that = window['component']
  // On sauve les données actuelles en modifiant le type de ligne

  dataToSend = JSON.parse(JSON.stringify(dataItem))
  dataToSend.line_type = 'contact'
  dataToSend.stage = 341

  that.utilities.save({
    displayName: that.displayName,
    request: 'contacts',
    dataToSend: dataToSend,
    action: 'update',
    callbackSuccess: function () {
      that.goToDetail('contacts', dataItem.id, {
        dontSave: true,
        replace: true,
      })
      that.openModal({
        title: 'Contact',
        body: 'La piste a été transformée en contact',
        showInput: false,
        showCancel: false,
      })
    },
    callbackError: function () {},
  })
}

function SpecAddFirstContact(dataItem, tableField) {
  var that = window['component']
  // On sauve les données actuelles en modifiant le type de ligne

  dataToSend = JSON.parse(JSON.stringify(dataItem))
  dataToSend.type = 'company'
  let index = that.currentSectionIndex
  newContactToSend = {
    first_name: dataToSend.first_name,
    last_name: dataToSend.last_name,
    email: dataToSend.email,
    phone: dataToSend.phone,
    mobile: dataToSend.mobile,
    address: dataToSend.address,
    zip: dataToSend.zip,
    city: dataToSend.city,
    country: dataToSend.country,
    position: dataToSend.position,
    company_id: dataToSend.id,
    type: 'employee',
  }

  // Je passe en company
  that.utilities.save({
    displayName: that.displayName,
    request: 'contacts',
    dataToSend: dataToSend,
    action: 'update',
    callbackSuccess: function () {
      dataItem.type = 'company'
      // Je rajoute les infos du contact en nouveau contact
      that.utilities.save({
        displayName: that.displayName,
        request: 'contacts',
        dataToSend: newContactToSend,
        action: 'add',
        callbackSuccess: function () {
          // j'ouvre un nouveau contact
          //    that.goToDetail("contacts", -1, { previousId:dataItem.id,dontSave:true,replace:true});

          //Sois ca mais on stock tba et on le remets
          //that.getListData({});
          //SOIt en Dessous (mieux) et on force data.item
          that.getSubTableData({
            tableField: tableField,
            minLines: 0,
            maxLines: tableField.maxLines || 300,
          })

          $('a[href="#navpills-6"]').click()
        },
        callbackError: function () {},
      })
    },
    callbackError: function () {},
  })
}

function SpecSetClosed(dataItem, refreshFunction = function () {}) {
  var that = window['component']
  that.openModal({
    title: 'Contact',
    body: 'Confirmez vous le passage en statut fermé ?',
    showInput: false,
    showCancel: true,
    onValidate: function (res) {
      if (Array.isArray(dataItem)) {
        dataItem.forEach((item) => {
          // Action immédiate
          ;(item.state = 'closed'),
            // Sauvegarde dans la base
            that.utilities.save({
              displayName: that.displayName,
              request: 'contacts',
              dataToSend: {
                id: item.id,
                state: 'closed',
                _log_message: 'Fermeture du contact : ' + item.display_name,
              },
              action: 'update',
            })
        })

        refreshFunction()
      } else {
        // Action immédiate
        ;(dataItem.state = 'closed'),
          // Sauvegarde dans la base
          that.utilities.save({
            displayName: that.displayName,
            request: 'contacts',
            dataToSend: {
              id: dataItem.id,
              state: 'closed',
              _log_message: 'Fermeture du contact : ' + dataItem.display_name,
            },
            action: 'update',
            callbackSuccess: refreshFunction,
            callbackError: function () {},
          })
      }
    },
  })
}

function SpecValidateEvent(dataItem, refreshFunction = function () {}) {
  var that = window['component']
  that.utilities.save({
    displayName: that.displayName,
    request: 'calendar',
    dataToSend: {
      id: dataItem.id,
      validation_state: 'validated',
      _log_message: 'Validation event : ' + dataItem.id,
    },
    action: 'update',
    callbackSuccess: refreshFunction,
    callbackError: function () {},
  })
}

function affectTicketToMe(dataItem, refreshFunction = function () {}) {
  var that = window['component']

  if (Array.isArray(dataItem)) {
    dataItem.forEach((item) => {
      that.utilities.save({
        displayName: that.displayName,
        request: 'support_headers',
        dataToSend: {
          id: item.id,
          affectation_user: [
            {
              id: that._g.userId,
              first_name: that._g.me.first_name,
              photo_select: that._g.me.photo.list,
            },
          ],
          _log_message: 'Affectation du ticket : ' + item.display_name,
        },
        action: 'update',
      })
    })

    refreshFunction()
  } else {
    // Sauvegarde dans la base
    that.utilities.save({
      displayName: that.displayName,
      request: 'support_headers',
      dataToSend: {
        id: dataItem.id,
        affectation_user: [
          {
            id: that._g.userId,
            first_name: that._g.me.first_name,
            photo_select: that._g.me.photo.list,
          },
        ],
        _log_message: 'Affectation du ticket : ' + dataItem.display_name,
      },
      action: 'update',
      callbackSuccess: refreshFunction,
      callbackError: function () {},
    })
  }
}

function setTicketAsSpam(dataItem, refreshFunction = function () {}) {
  var that = window['component']
  that.openModal({
    title: 'Contact',
    body: 'Confirmez vous le passage en spam ?',
    showInput: false,
    showCancel: true,
    onValidate: function (res) {
      // Action immédiate
      dataItem.state = 545
      dataItem.affectation = that._g.userId
      // Sauvegarde dans la base
      that.utilities.save({
        displayName: that.displayName,
        request: 'support_headers',
        dataToSend: {
          id: dataItem.id,
          affectation: that._g.userId,
          state: 545,
          _log_message: 'Passage en spam : ' + dataItem.display_name,
        },
        action: 'update',
        callbackSuccess: refreshFunction,
        callbackError: function () {},
      })
    },
  })
}

function SpecExcludeMail(dataItem, refreshFunction = function () {}) {
  var that = window['component']

  that.utilities.save({
    displayName: that.displayName,
    request: 'contacts',
    dataToSend: {
      id: dataItem.contact_id,
      no_automatic_mail: true,
    },
    action: 'update',
    callbackSuccess: function () {
      refreshFunction()
      that.currentDataTable.draw('page')
    },
    callbackError: function () {},
  })
}

function SpecAttachToMe(dataItem, refreshFunction = function () {}) {
  var that = window['component']

  that.utilities.save({
    displayName: that.displayName,
    request: 'relations',
    dataToSend: {
      id: dataItem.id,
      stage_id: 532,
      affected_to: that._g.userId,
    },
    action: 'update',
    callbackSuccess: function () {
      alert('on go back')
      that.location.back()
    },
    callbackError: function () {},
  })
}

function SpecAddEventContact(dataItem) {
  var beginString =
    dataItem.next_action_date.format('YYYY-MM-DD') +
    'T' +
    dataItem.next_action_begin_time.format('HH:mm:ss')
  var endString =
    dataItem.next_action_date.format('YYYY-MM-DD') +
    'T' +
    dataItem.next_action_end_time.format('HH:mm:ss')
  var summaryString =
    (dataItem.next_action_type_display
      ? dataItem.next_action_type_display
      : '') +
    ' ' +
    (dataItem.display_name ? dataItem.display_name : '')
  var toSend = {
    summary: summaryString,
    description: dataItem.next_action_comment,
    start: {
      dateTime: beginString,
      timeZone: 'Europe/Paris',
    },
    end: {
      dateTime: endString,
      timeZone: 'Europe/Paris',
    },
  }

  addEvent(toSend)
}

function SpecAffectToMe(dataItem) {
  var that = window['component']

  return {
    affectation_user: [
      {
        id: that._g.userId,
        first_name: that._g.me.first_name,
        photo_select: that._g.me.photo.list,
      },
    ],
  }
}

function addEvent(event) {
  var that = window['component']

  var URL = that._g.restRoot + 'app/google/addevent'
  that.utilities.http
    .post(
      URL,
      {
        data: event,
      },
      {
        headers: that.utilities.getHTTPHeaders(),
      },
    )
    .subscribe((data) => {
      alert('OK')
    })
}
